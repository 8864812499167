'use strict';
console.log("under instructions controller js")
angular.module('kljDigitalLibraryApp')
    .controller('InstructionsCtrl', ["$scope", "$rootScope", "$timeout", "$state",
        "appConfig", "$stateParams",
        function($scope, $rootScope, $timeout, $state,
            appConfig, $stateParams) {

            

            

        }
    ]);